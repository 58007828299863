
let gragh

export function setChartObj(obj) {
  gragh = obj
}
export function hasFold(name) {
  return !!map[name]
}

let tree
export function setTreeData(treeData) {
  tree = treeData
}

// 通过name字段搜索tree找到对应节点
function searchbyName(name) {
  function dfs(node) {
    if (node.name === name) {
      return node
    }
    for (let i = 0; i < ((node.childNode || []).length); i++) {
      const res = dfs(node.childNode[i])
      if (res) return res
    }
    return false
  }
  return dfs(tree)
}

// 层序遍历找到索要要删除的子节点id
function levelTravel(root, map = {}) {
  for (let i = 0; i < (root.childNode || []).length ; i++) {
    const node = root.childNode[i]
    map[node.id] = true
    levelTravel(node, map)
  }
  return map
}

const map = {}
export function fold(name) {
  // map中没有，则加入
  const { data } = option.series[0]
  if (!map[name]) {
    // 在树中找到对应的节点
    const root = searchbyName(name)
    // 通过根节点进行层序遍历 找到所有要删除的节点的id 存起来
    const idMap = levelTravel(root)
    // 找到在data中所有要删除的节点
    const foldList = data.filter(item => idMap[item.nodeId])
    // 找到在data中所有要删除的节点的index
    const foldIndexList = foldList.map(item => data.indexOf(item))
    // 删除操作
    foldIndexList.forEach((item, index) => {
      data.splice(item - index, 1)
    })
    // 保存
    map[name] = foldList

    console.log(foldIndexList)
    console.log(foldList)
    // const item = data[index]
    // data.splice(index, 1)

  } else {
    data.push(...map[name])
    delete(map[name])
  }
  gragh.setOption(option)
}

export function setGraph(data, links) {
  
  data.forEach(item => {
    item.nodeId = item.id
    delete(item.id)
  })
  getNodeStyle(data)
  console.log('------------------')
  const map = {}, map2 = []
  data.forEach(item => {
    const name = item.name
    map[name] = map[name] ? map[name] + 1 : 1
    if (map[name] > 1) {
      map2.push(item)
    }
  })
 
  console.log(Array.from(new Set(data.map(item => item.name))).length)
  console.log('------------------')
  
  console.log(data, links)
 
  gragh && gragh.setOption(editOptions(data, links), true)
}

const option = {
  //你的代码
  title: { text: '' },

  tooltip: {
    formatter: function (x) {
      return x.data.des;
    }
  },
  series: [
    {
      type: 'graph',
      layout: 'force',
      symbolSize: 80,
      roam: true,
      edgeSymbol: ['circle', 'arrow'],
      edgeSymbolSize: [4, 10],
      edgeLabel: {
        normal: {
          textStyle: {
            fontSize: 20
          }
        }
      },
      force: {
        repulsion: 2500,
        edgeLength: [10, 50]
      },
      draggable: true,
      itemStyle: {
        normal: {
          color: '#4b565b'
        }
      },
      lineStyle: {
        normal: {
          width: 2,
          color: '#4b565b'

        }
      },
      edgeLabel: {
        normal: {
          show: true,
          formatter: function (x) {
            return x.data.name;
          }
        }
      },
      label: {
        normal: {
          show: true,
          textStyle: {
          }
        }
      },
    }]
};

function editOptions(data, links) {
  option.series[0].data = data
  option.series[0].links = links
  console.log(1111, option)
  return option
}

export function dataKeys(array) {
  array.forEach(item => {
    item.name = item.content
    item.category = item.level
  })
}

export function linkKeys(array) {
  array.forEach(item => {
    
    item.symbolSize = 10
  })
}


function getNodeStyle(arr) {
  console.log(arr)
  const map = {
    1: ['red', 85],
    2: ['blue', 80],
    3: ['yellow', 75],
    4: ['green', 70],
    default: ['orange', 70],
  }
  arr.forEach(item => {
    const info = map[item.category] || map.default
    // console.log(item.category, map[item.category])
    item.symbolSize = info[1]
    item.itemStyle = {
      normal: {
        color: info[0]
      }
    }
  })
}
<template>
  <div class="container" style="height: 100%; width: 100%; overflow-y: auto; scrollbar-width: thin;">
    <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="节点id" prop="name">
        <div style="display: flex;">
          <el-input style="margin-right: 3px" v-model="node.nodeId" placeholder="节点id" :disabled="true"></el-input>
          <el-button @click="getInfo" type="primary">查询</el-button>
        </div>
      </el-form-item>
      <el-form-item label="新增节点" prop="name">
        <div style="display: flex;">
          <el-input style="margin-right: 3px" v-model="newNodeName" placeholder="新增节点名"></el-input>
          <el-button @click="addNode" type="primary">新增</el-button>
        </div>
      </el-form-item>
      <el-form-item label="节点名称" prop="name">
        <div style="display: flex;">
          <el-input style="margin-right: 3px" v-model="node.name" placeholder="节点名称"></el-input>
          <el-button @click="saveNode" type="primary">保存编辑</el-button>
        </div>
      </el-form-item>
      <el-form-item label="描述" prop="name">
        <div style="">
          <el-input type="textarea" autosize v-model="knowledge" placeholder="节点描述"></el-input>
          <el-button @click="saveDesc" type="primary">保存编辑</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="danger" @click="deleteNode">删除节点</el-button>
    <el-button type="success" @click="returnFirstPage" v-if="!isFirstPage">返回首页</el-button>
    <el-button @click="$emit('fold'); hasFold = !hasFold;" type="primary" v-if="node.category > 1">{{ hasFold ? '展开' : '收起' }}</el-button>
  </div>
</template>

<script>
import api from '@/api'
import { hasFold } from '@/utils/setGraph'
export default {
  props: {
    node: {
      type: Object,
      default: () => {return {}}
    },
    isFirstPage: {
      type: Boolean,
      default: true
    },
  },
  name: 'node-info',
  data() {
    return {
      ruleForm: {
        name: '',
      },
      newNodeName: '',
      knowledge: '',
      hasFold: false,
    }
  },
  components: {
  },
  watch: {
    async node(newVal) {
      console.log(newVal)
      this.knowledge = (await api.mysqlQuery(newVal.nodeId || 0)).data.knowledgeList[0]
      this.hasFold = hasFold(newVal.name)
    }
  },
  mounted() {
    window.abc = 'wute'
  },
  methods: {
    getInfo() {
      this.newNodeName = ''
      this.$emit('SearchNeo4jNode')
    },

    // 
    async deleteNode() {
      // console.log({id: this.node.nodeId, level: this.node.category})
      if (!this.node.nodeId) {
        this.$message.error('请选中某个节点后删除！')
        return
      }
      
      const done = async () => {
        const { data, code } = (await api.neo4jDelete({id: this.node.nodeId, level: this.node.category}))
        this.$message({
          type: 'success',
          message: data 
        })
        if (code === 0) {
          this.returnFirstPage()
        }
      }

      if (this.node.category < 4) {
        this.$confirm('您删除的节点为知识图谱的骨架节点，确定执行删除吗?')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      } else {
        done()
      }
    },
    
    async saveNode() {
      if (!this.node.name) {
        this.$message.error('编辑节点名不能为空！')
        return
      }
      // console.log({id: this.node.nodeId, content: this.node.name})
      const { data , code } = (await api.neo4jUpdate({id: this.node.nodeId, content: this.node.name}))
      this.$message({
        type: 'success',
        message: data
      })
      if (code === 0) {
        this.getInfo()
      }
    },
   
    returnFirstPage() {
      this.$emit('returnFirstPage')
    },

    async saveDesc() {
      if (!this.knowledge) {
        this.$message.error('描述不能为空！')
        return
      }
      // console.log({id: this.node.nodeId, content: this.node.name})
      const { data , code } = (await api.mysqlUpdate({id: this.node.nodeId, content: this.knowledge, knowledgeId: this.node.knowledgeId}))
      this.$message({
        type: 'success',
        message: data 
      })
      if (code === 0) {
        this.getInfo()
      }
    },

    async addNode() {
      if (!this.newNodeName) {
        this.$message.error('新增节点名不能为空！')
        return
      }
      if (!this.node.nodeId) {
        this.$message.error('请选中某个节点后新增！')
        return
      }
      console.log({id: this.node.nodeId, content: this.node.name})
      const {data, code} = await api.neo4jAdd({id: this.node.nodeId, content: this.newNodeName})
      this.$message({
        type: 'success',
        message: data
      })
      if (code === 0) {
        this.getInfo()
      }
    },

    refresh() {
      
    }
  }

}


</script>


<style lang="scss" scoped>
.container::v-deep {
  height: 100%;
  box-sizing: border-box;
  padding: 20px 20px 0 0;
  .el-form-item:hover{
    background: #f7f7f7c4;
    transition: all 0.3s linear;
  }
}
</style>

<template>
  <div class="home">
    <div class="nav-bar">
      <div style="display: flex;">
        <span>
          数据库课程知识图谱
        </span>
        <el-menu :default-active="activeIndex" @select="activeIndexChange" class="el-menu-demo" mode="horizontal" background-color="#304156"
          text-color="#fff" active-text-color="#de5971">
          <el-menu-item index="1">主页</el-menu-item>
          <el-menu-item index="2">动态图谱</el-menu-item>
          <el-submenu index="3">
            <template slot="title">快速入口</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
            <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu>
      </div>
      <div style="display: flex; align-items: center;">
        <header-search @searchSelect="searchSelect"></header-search>
      </div>
    </div>
    <div class="main-container" v-if="!firstLoadPage">
      <div id="main" class="gragh-container">
      </div>
      <div class="node-info-container">
        <node-info :node="nodeInfo" @fold="fold" @SearchNeo4jNode="searchNeo4jNode" @returnFirstPage="getAllNodes" :isFirstPage="isFirstPage"></node-info>
      </div>
    </div>
    <div class="main-container first-page" @click="closeBanner" v-else>
    </div>
  </div>
</template>

<script>
import api from '@/api.js'
// @ is an alias to /src
import * as echarts from 'echarts';
import nodeInfo from './node.vue'
import HeaderSearch from '@/components/HeaderSearch/index.vue'
import { setChartObj, setGraph, fold, setTreeData } from '@/utils/setGraph'
export default {
  name: 'HomeView',
  components: {
    nodeInfo,
    HeaderSearch
  },
  data() {
    return {
      firstLoadPage: true,
      nodeInfo: {},
      activeIndex: '1',
      isFirstPage: true,
    }
  },
  watch: {

  },
  methods: {
    closeBanner() {
      this.firstLoadPage = false
      this.$nextTick(this.initGraph)
    },
    activeIndexChange(newVal) {
      if (newVal === '2') {
        this.getAllNodes()
      }
    },
    async initGraph() {
      const chartDom = document.getElementById('main');
      const myChart = echarts.init(chartDom);
      setChartObj(myChart)

      await api.initGraph()
      this.getAllNodes()
      // myChart.setOption(option);

      myChart.on('click', async (param) => {
        if (param.dataType === 'node') {
          // 点击节点
          console.log('点击节点', param)
          this.nodeInfo = param.data

        } else {
          // 点击边
          console.log('点击了边', param)
        }
      })
    },

    searchSelect(item) {
      console.log(111, item.nodeId)
      this.searchNeo4jNode(item.nodeId)

    },
    fold() {
      fold(this.nodeInfo.name)
    },

    async getAllNodes() {
      const res = await api.neo4jAll()
      console.log('getAllNodes', res)
      setGraph(res.data.data, res.data.links)
      setTreeData(res.data.treeData)
      this.nodeInfo = {}
      this.isFirstPage = true
    },

    async searchNeo4jNode(id) {
      id = id ? id: this.nodeInfo.nodeId
      const res = await api.neo4jGet(id)
      setGraph(res.data.data, res.data.links)
      this.isFirstPage = false
      console.log(222,id, res.data.data.find(item => item.nodeId === id))
      this.nodeInfo = res.data.data.find(item => item.nodeId === id)
      return res
    }
  },
  async mounted() {
    console.log(this.$route.query.id)
    if (typeof this.$route.query.id === 'number') {
      console.log('mounted')
      this.searchNeo4jNode(this.$route.query.id)
    }
  },
  async activated () {
    console.log('actived')
    console.log(this.$route.query.id)
    if (typeof this.$route.query.id === 'string') {
      console.log('actived')
      console.log(111, await this.searchNeo4jNode(this.$route.query.id))
      this.nodeInfo = (await this.searchNeo4jNode(this.$route.query.id)).data.data[0]
    }
  },

  
}


</script>


<style lang="scss" scoped>
.nav-bar {
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: #304156;

  span {
    color: #fff;
    line-height: 60px;
    padding: 0 20px;
  }
}

.main-container {
  background: #eee;
  width: 100%;
  display: flex;
  height: calc(100vh - 60px);

  .gragh-container {
    flex: 1 1 0;
    height: calc(100vh - 60px);
    background: #fff;
  }

  .node-info-container {
    margin-left: 5px;
    background: #fff;
    width: 400px
  }
}

.first-page {
  background: url(@/assets/banner.png) !important;
  background-size: cover;
  background-repeat: no-repeat !important;
}
</style>

<style>
body {
  padding: 0;
  margin: 0;
}
</style>
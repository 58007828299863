<template>
  <div>
    <div class="nav-bar">
      <div style="display: flex;">
        <span>
          数据库课程知识图谱
        </span>
        <el-menu class="el-menu-demo" mode="horizontal" background-color="#304156"
          text-color="#fff" active-text-color="#de5971">
          <el-menu-item index="1">主页</el-menu-item>
          <el-menu-item index="2">动态图谱</el-menu-item>
          <el-submenu index="3">
            <template slot="title">快速入口</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
            <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu>
      </div>
      <div style="display: flex; align-items: center;">
      </div>
    </div>
    <div class="container" v-loading="loading" style="height: calc(100vh - 60px); width: 100%;">
      <div class="search-container">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <el-input v-model="desc" @change="querySearch()" placeholder="请输入描述内容" style="margin: 20px 0"></el-input>
          <el-button type="primary" style="margin-left: 5px;height: 39px;" @click="querySearch()">查询</el-button>
          <el-button type="danger" style="margin-left: 5px;height: 39px;" @click="$router.push('/')">返回主页</el-button>
        </div>
        <div v-for="(item, index) in options" :key="'k' + index" class="content-item" @click="$router.push('/?id=' + item.id)">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.knowledge }}</div>
        </div>
        <el-empty v-if="!first && !options.length" description="暂时没有相关结果 换个关键词吧"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  props: {
    node: {
      type: Object,
      default: () => {return {}}
    }
  },
  name: 'search-view',
  data() {
    return {
      desc: '',
      options: [],
      first: true,
      loading: false
    }
  },

  components: {
  },

  watch: {
   
  },
  methods: {
    async querySearch() {
      this.loading = true
      const query = this.desc
      if (query !== '') {
        console.log(query)
        const res = await api.mysqlVague({ content: query })
        console.log(res)
        this.options = [...res.data]
      } else {
        this.options = []
      }
      this.first = false
      this.loading = false
    }
  }

}


</script>


<style lang="scss" scoped>
.container {
  .search-container {
    width: 800px;
    margin: 0 auto;
  }
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: #304156;

  span {
    color: #fff;
    line-height: 60px;
    padding: 0 20px;
  }
}

.content-item {
  box-sizing: border-box;
  padding: 20px 20px;
  border-bottom: 1px solid #11111117;
  text-align: left;
  color: #304156;
  .title {
    font-size: 1.1em;
    margin-bottom: 5px;
  }
  .content {
    color: #333333d0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

</style>

<template>
  <div :class="{'show':show}" class="header-search">
    <div class="search-icon" icon-class="search" @click.stop="$router.push('/search')" />
    <el-select
      ref="headerSearchSelect"
      v-model="search"
      :remote-method="querySearch"
      filterable
      default-first-option
      remote
      placeholder="Search"
      class="header-search-select"
      @change="change"
    >
      <el-option v-for="item in options" :key="item.id" :value="item" :label="item.title" />
    </el-select>
  </div>
</template>

<script>
// fuse is a lightweight fuzzy-search module
// make search results more in line with expectations
import api from '@/api'

export default {
  name: 'HeaderSearch',
  data() {
    return {
      search: '',
      options: [],
      searchPool: [],
      show: false,
      fuse: undefined
    }
  },
  computed: {
    routes() {
      return this.$store.getters.permission_routes
    }
  },
  watch: {
    routes() {
    },
    show(value) {
      if (value) {
        document.body.addEventListener('click', this.close)
      } else {
        document.body.removeEventListener('click', this.close)
      }
    }
  },
  mounted() {
  },
  methods: {
    click() {
      this.show = !this.show
      if (this.show) {
        this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.focus()
      }
    },
    close() {
      this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.blur()
      this.options = []
      this.show = false
    },
    change(val) {
      console.log(val)
      this.options = []
      this.$emit('searchSelect', val)
    },
    async querySearch(query) {
      if (query !== '') {
        console.log(query)
        const res = await api.mysqlVague({ content: query })
        console.log(res)
        this.options = [...res.data]
        // this.options = res.data
        console.log(this.options)
      } else {
        this.options = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-icon {
  margin-right: 10px;
  height: 25px;
  width: 25px;
  background: url(@/assets/search.png);
  background-size: 100%;
}
.header-search {
  font-size: 0 !important;

  .search-icon {
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
    vertical-align: middle;
  }

  .header-search-select {
    font-size: 18px;
    transition: width 0.2s;
    width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;

    ::v-deep .el-input__inner {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none !important;
      border-bottom: 1px solid #d9d9d9;
      vertical-align: middle;
    }
  }

  &.show {
    .header-search-select {
      width: 210px;
      margin-left: 10px;
    }
  }
}
</style>

import request from '@/utils/request'

export default {
  initGraph() {
    return request({
      url: '/graph/init',
      method: 'post',
    })
  },
  // 增加指定节点的关联信息 
  neo4jAdd(data) {
    return request({
      url: '/neo4j/add/one',
      method: 'post',
      data
    })
  },
  // 删除指定节点的关联信息 
  neo4jDelete(data) {
    return request({
      url: '/neo4j/delete/one',
      method: 'post',
      data
    })
  },
  // 获取所有节点信息  
  neo4jAll() {
    return request({
      url: '/neo4j/get/all',
      method: 'post',
    })
  },
  // 获取指定节点的关联信息 
  neo4jGet(id) {
    return request({
      url: '/neo4j/get/one',
      method: 'post',
      data: { id }
    })
  },
  // 修改指定节点的关联信息 
  neo4jUpdate(data) {
    return request({
      url: '/neo4j/update/one',
      method: 'post',
      data
    })
  },

  // 新增知识描述内容  
  mysqlAdd() {
    return request({
      url: '/mysql/add/knowledge',
      method: 'post',
    })
  },

  // 删除知识描述内容  
  mysqlDelete() {
    return request({
      url: '/mysql/delete/knowledge',
      method: 'post',
    })
  },

  // 根据节点id查询知识描述内容   
  mysqlQuery(id) {
    return request({
      url: '/mysql/query/knowledge',
      method: 'post',
      data: { id }
    })
  },

  // 修改知识描述内容   
  mysqlUpdate(data) {
    return request({
      url: '/mysql/update/knowledge',
      method: 'post',
      data
    })
  },

  // 根据内容模糊查询知识描述内容    
  mysqlVague(query) {
    return request({
      url: '/mysql/vague/knowledge',
      method: 'post',
      data: query
    })
  },


}